
// @ts-nocheck


export const localeCodes =  [
  "fr",
  "es",
  "de",
  "nl",
  "en"
]

export const localeLoaders = {
  "fr": [{ key: "../public/locales/shared.js", load: () => import("../public/locales/shared.js" /* webpackChunkName: "locale__vercel_path0_public_locales_shared_js" */), cache: true },
{ key: "../public/locales/fr/fr.common.json", load: () => import("../public/locales/fr/fr.common.json" /* webpackChunkName: "locale__vercel_path0_public_locales_fr_fr_common_json" */), cache: true },
{ key: "../public/locales/fr/fr.formsFields.json", load: () => import("../public/locales/fr/fr.formsFields.json" /* webpackChunkName: "locale__vercel_path0_public_locales_fr_fr_formsFields_json" */), cache: true },
{ key: "../public/locales/fr/fr.lisa.json", load: () => import("../public/locales/fr/fr.lisa.json" /* webpackChunkName: "locale__vercel_path0_public_locales_fr_fr_lisa_json" */), cache: true }],
  "es": [{ key: "../public/locales/shared.js", load: () => import("../public/locales/shared.js" /* webpackChunkName: "locale__vercel_path0_public_locales_shared_js" */), cache: true },
{ key: "../public/locales/es/es.common.json", load: () => import("../public/locales/es/es.common.json" /* webpackChunkName: "locale__vercel_path0_public_locales_es_es_common_json" */), cache: true },
{ key: "../public/locales/es/es.formsFields.json", load: () => import("../public/locales/es/es.formsFields.json" /* webpackChunkName: "locale__vercel_path0_public_locales_es_es_formsFields_json" */), cache: true },
{ key: "../public/locales/es/es.lisa.json", load: () => import("../public/locales/es/es.lisa.json" /* webpackChunkName: "locale__vercel_path0_public_locales_es_es_lisa_json" */), cache: true }],
  "de": [{ key: "../public/locales/shared.js", load: () => import("../public/locales/shared.js" /* webpackChunkName: "locale__vercel_path0_public_locales_shared_js" */), cache: true },
{ key: "../public/locales/de/de.common.json", load: () => import("../public/locales/de/de.common.json" /* webpackChunkName: "locale__vercel_path0_public_locales_de_de_common_json" */), cache: true },
{ key: "../public/locales/de/de.formsFields.json", load: () => import("../public/locales/de/de.formsFields.json" /* webpackChunkName: "locale__vercel_path0_public_locales_de_de_formsFields_json" */), cache: true },
{ key: "../public/locales/de/de.lisa.json", load: () => import("../public/locales/de/de.lisa.json" /* webpackChunkName: "locale__vercel_path0_public_locales_de_de_lisa_json" */), cache: true }],
  "nl": [{ key: "../public/locales/shared.js", load: () => import("../public/locales/shared.js" /* webpackChunkName: "locale__vercel_path0_public_locales_shared_js" */), cache: true },
{ key: "../public/locales/nl/nl.common.json", load: () => import("../public/locales/nl/nl.common.json" /* webpackChunkName: "locale__vercel_path0_public_locales_nl_nl_common_json" */), cache: true },
{ key: "../public/locales/nl/nl.formsFields.json", load: () => import("../public/locales/nl/nl.formsFields.json" /* webpackChunkName: "locale__vercel_path0_public_locales_nl_nl_formsFields_json" */), cache: true },
{ key: "../public/locales/nl/nl.lisa.json", load: () => import("../public/locales/nl/nl.lisa.json" /* webpackChunkName: "locale__vercel_path0_public_locales_nl_nl_lisa_json" */), cache: true }],
  "en": [{ key: "../public/locales/shared.js", load: () => import("../public/locales/shared.js" /* webpackChunkName: "locale__vercel_path0_public_locales_shared_js" */), cache: true },
{ key: "../public/locales/en/en.common.json", load: () => import("../public/locales/en/en.common.json" /* webpackChunkName: "locale__vercel_path0_public_locales_en_en_common_json" */), cache: true },
{ key: "../public/locales/en/en.formsFields.json", load: () => import("../public/locales/en/en.formsFields.json" /* webpackChunkName: "locale__vercel_path0_public_locales_en_en_formsFields_json" */), cache: true },
{ key: "../public/locales/en/en.lisa.json", load: () => import("../public/locales/en/en.lisa.json" /* webpackChunkName: "locale__vercel_path0_public_locales_en_en_lisa_json" */), cache: true }]
}

export const vueI18nConfigs = [
  
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": false,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [
    {
      "code": "fr",
      "language": "fr-fr",
      "files": [
        "/vercel/path0/public/locales/shared.js",
        "/vercel/path0/public/locales/fr/fr.common.json",
        "/vercel/path0/public/locales/fr/fr.formsFields.json",
        "/vercel/path0/public/locales/fr/fr.lisa.json"
      ],
      "corporate": "entreprises"
    },
    {
      "code": "es",
      "language": "es-es",
      "files": [
        "/vercel/path0/public/locales/shared.js",
        "/vercel/path0/public/locales/es/es.common.json",
        "/vercel/path0/public/locales/es/es.formsFields.json",
        "/vercel/path0/public/locales/es/es.lisa.json"
      ],
      "corporate": "empresas"
    },
    {
      "code": "de",
      "language": "de-de",
      "files": [
        "/vercel/path0/public/locales/shared.js",
        "/vercel/path0/public/locales/de/de.common.json",
        "/vercel/path0/public/locales/de/de.formsFields.json",
        "/vercel/path0/public/locales/de/de.lisa.json"
      ],
      "corporate": "unternehmen"
    },
    {
      "code": "nl",
      "language": "nl-nl",
      "files": [
        "/vercel/path0/public/locales/shared.js",
        "/vercel/path0/public/locales/nl/nl.common.json",
        "/vercel/path0/public/locales/nl/nl.formsFields.json",
        "/vercel/path0/public/locales/nl/nl.lisa.json"
      ],
      "corporate": "bedrijven"
    },
    {
      "code": "en",
      "language": "en-gb",
      "files": [
        "/vercel/path0/public/locales/shared.js",
        "/vercel/path0/public/locales/en/en.common.json",
        "/vercel/path0/public/locales/en/en.formsFields.json",
        "/vercel/path0/public/locales/en/en.lisa.json"
      ],
      "corporate": "corporate"
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix",
  "lazy": true,
  "langDir": "./public/locales/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": false
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "config",
  "pages": {
    "corporate/index": {
      "fr": "/entreprises",
      "en": "/corporate",
      "es": "/empresas",
      "de": "/unternehmen",
      "nl": "/bedrijven"
    },
    "corporate/contact/index": {
      "fr": "/entreprises/contact",
      "en": "/corporate/contact",
      "es": "/empresas/contacto",
      "de": "/unternehmen/kontakt",
      "nl": "/bedrijven/contacten"
    },
    "corporate/contact/form/[uid]": {
      "fr": "/entreprises/contact/[uid]",
      "en": "/corporate/contact/[uid]",
      "es": "/empresas/contacto/[uid]",
      "de": "/unternehmen/kontakt/[uid]",
      "nl": "/bedrijven/contacten/[uid]"
    },
    "corporate/support/[uid]/index": {
      "fr": "/entreprises/aide/[uid]",
      "en": "/corporate/support/[uid]",
      "es": "/empresas/soporte/[uid]",
      "de": "/unternehmen/hilfe/[uid]",
      "nl": "/bedrijven/ondersteuning/[uid]"
    },
    "corporate/support/[uid]/[child]/index": {
      "fr": "/entreprises/aide/[uid]/[child]",
      "en": "/corporate/support/[uid]/[child]",
      "es": "/empresas/soporte/[uid]/[child]",
      "de": "/unternehmen/hilfe/[uid]/[child]",
      "nl": "/bedrijven/ondersteuning/[uid]/[child]"
    },
    "corporate/services/[uid]/index": {
      "fr": "/entreprises/services/[uid]",
      "en": "/corporate/services/[uid]",
      "es": "/empresas/servicios/[uid]",
      "de": "/unternehmen/serviceleistungen/[uid]",
      "nl": "/bedrijven/diensten/[uid]"
    },
    "corporate/services/[uid]/[child]/index": {
      "fr": "/entreprises/services/[uid]/[child]",
      "en": "/corporate/services/[uid]/[child]",
      "es": "/empresas/servicios/[uid]/[child]",
      "de": "/unternehmen/serviceleistungen/[uid]/[child]",
      "nl": "/bedrijven/diensten/[uid]/[child]"
    },
    "corporate/support/faq/faqs": {
      "fr": "/entreprises/aide/faq",
      "en": "/corporate/support/faq",
      "es": "/empresas/soporte/preguntas-frecuentes",
      "de": "/unternehmen/hilfe/haeufig-gestellte-fragen",
      "nl": "/bedrijven/ondersteuning/veel-gestelde-vragen"
    },
    "corporate/support/faq/[faq]/index": {
      "fr": "/entreprises/aide/faq/[faq]",
      "en": "/corporate/support/faq/[faq]",
      "es": "/empresas/soporte/preguntas-frecuentes/[faq]",
      "de": "/unternehmen/hilfe/haeufig-gestellte-fragen/[faq]",
      "nl": "/bedrijven/ondersteuning/veel-gestelde-vragen/[faq]"
    },
    "corporate/about/[uid]/index": {
      "fr": "/entreprises/a-propos/[uid]",
      "en": "/corporate/about-april/[uid]",
      "es": "/empresas/acerca-de-april/[uid]",
      "de": "/unternehmen/ueber-april/[uid]",
      "nl": "/bedrijven/over-april/[uid]"
    },
    "corporate/about/[uid]/[child]/index": {
      "fr": "/entreprises/a-propos/[uid]/[child]",
      "en": "/corporate/about-april/[uid]/[child]",
      "es": "/empresas/acerca-de-april/[uid]/[child]",
      "de": "/unternehmen/ueber-april/[uid]/[child]",
      "nl": "/bedrijven/over-april/[uid]/[child]"
    },
    "corporate/legal/[uid]/index": {
      "fr": "/entreprises/legal/[uid]",
      "en": "/corporate/legal/[uid]",
      "es": "/empresas/legal/[uid]",
      "de": "/unternehmen/legal/[uid]",
      "nl": "/bedrijven/legal/[uid]"
    },
    "corporate/legal/[uid]/[child]/index": {
      "fr": "/entreprises/legal/[uid]/[child]",
      "en": "/corporate/legal/[uid]/[child]",
      "es": "/empresas/legal/[uid]/[child]",
      "de": "/unternehmen/legal/[uid]/[child]",
      "nl": "/bedrijven/legal/[uid]/[child]"
    },
    "corporate/about/news/index": {
      "fr": "/entreprises/a-propos/actualites",
      "en": "/corporate/about-april/news",
      "es": "/empresas/acerca-de-april/noticias",
      "de": "/unternehmen/ueber-april/neuigkeiten",
      "nl": "/bedrijven/over-april/nieuws"
    },
    "corporate/about/news/[article]/index": {
      "fr": "/entreprises/a-propos/actualites/[article]",
      "en": "/corporate/about-april/news/[article]",
      "es": "/empresas/acerca-de-april/noticias/[article]",
      "de": "/unternehmen/ueber-april/neuigkeiten/[article]",
      "nl": "/bedrijven/over-april/nieuws/[article]"
    },
    "corporate/[offer]/index": {
      "fr": "/entreprises/[offer]",
      "en": "/corporate/[offer]",
      "es": "/empresas/[offer]",
      "de": "/unternehmen/[offer]",
      "nl": "/bedrijven/[offer]"
    },
    "corporate/guide/index": {
      "fr": "/entreprises/assurance/guide",
      "en": "/corporate/insurance/guide",
      "es": "/empresas/seguro/guia",
      "de": "/unternehmen/versicherungen/guide",
      "nl": "/bedrijven/verzekeringen/gids"
    },
    "corporate/guide/[uid]": {
      "fr": "/entreprises/assurance/guide/[uid]",
      "en": "/corporate/insurance/guide/[uid]",
      "es": "/empresas/seguro/guia/[uid]",
      "de": "/unternehmen/versicherungen/guide/[uid]",
      "nl": "/bedrijven/verzekeringen/gids/[uid]"
    },
    "corporate/[offer]/guide/index": {
      "fr": "/entreprises/[offer]/guide",
      "en": "/corporate/[offer]/guide",
      "es": "/empresas/[offer]/guia",
      "de": "/unternehmen/[offer]/guide",
      "nl": "/bedrijven/[offer]/gids"
    },
    "corporate/[offer]/guide/[uid]": {
      "fr": "/entreprises/[offer]/guide/[uid]",
      "en": "/corporate/[offer]/guide/[uid]",
      "es": "/empresas/[offer]/guia/[uid]",
      "de": "/unternehmen/[offer]/guide/[uid]",
      "nl": "/bedrijven/[offer]/gids/[uid]"
    },
    "corporate/guides-sitemap/index": {
      "fr": "/entreprises/tous-nos-guides",
      "en": "/corporate/all-our-guides",
      "es": "/empresas/todas-nuestras-guias",
      "de": "/unternehmen/alle-unsere-guides",
      "nl": "/bedrijven/alle-gidsen"
    },
    "corporate/taxonomy/index": {
      "fr": "/entreprises/guides-thematiques",
      "en": "/corporate/guides-thematiques",
      "es": "/empresas/guides-thematiques",
      "de": "/unternehmen/guides-thematiques",
      "nl": "/bedrijven/guides-thematiques"
    },
    "corporate/taxonomy/[uid]": {
      "fr": "/entreprises/guides/[uid]",
      "en": "/corporate/guides/[uid]",
      "es": "/empresas/guias/[uid]",
      "de": "/unternehmen/leïtfaden/[uid]",
      "nl": "/bedrijven/gidsen/[uid]"
    },
    "corporate/guides-themes/index": {
      "fr": "/entreprises/guides",
      "en": "/corporate/guides",
      "es": "/empresas/guias",
      "de": "/unternehmen/leïtfaden",
      "nl": "/bedrijven/gidsen"
    },
    "corporate/[offer]/[product]/index": {
      "fr": "/entreprises/[offer]/[product]/",
      "en": "/corporate/[offer]/[product]/",
      "es": "/empresas/[offer]/[product]/",
      "de": "/unternehmen/[offer]/[product]/",
      "nl": "/bedrijven/[offer]/[product]/"
    },
    "corporate/[offer]/[product]/[form]/index": {
      "fr": "/entreprises/[offer]/[product]/[form]",
      "en": "/corporate/[offer]/[product]/[form]",
      "es": "/empresas/[offer]/[product]/[form]",
      "de": "/unternehmen/[offer]/[product]/[form]",
      "nl": "/bedrijven/[offer]/[product]/[form]"
    },
    "corporate/regions/destinations": {
      "fr": "/entreprises/destinations",
      "en": "/corporate/destinations",
      "es": "/empresas/destinos",
      "de": "/unternehmen/ziellaender",
      "nl": "/bedrijven/destinations"
    },
    "corporate/regions/[region]/index": {
      "fr": "/entreprises/destinations/[region]",
      "en": "/corporate/destinations/[region]",
      "es": "/empresas/destinos/[region]",
      "de": "/unternehmen/ziellaender/[region]",
      "nl": "/bedrijven/destinations/[region]"
    },
    "corporate/regions/[region]/[country]/index": {
      "fr": "/entreprises/destinations/[region]/[country]",
      "en": "/corporate/destinations/[region]/[country]",
      "es": "/empresas/destinos/[region]/[country]",
      "de": "/unternehmen/ziellaender/[region]/[country]",
      "nl": "/bedrijven/destinations/[region]/[country]"
    },
    "corporate/landing/[uid]": {
      "fr": "/entreprises/landing/[uid]",
      "en": "/corporate/landing/[uid]",
      "es": "/empresas/landing/[uid]",
      "de": "/unternehmen/landing/[uid]",
      "nl": "/bedrijven/landing/[uid]"
    },
    "corporate/login/index": {
      "fr": "/entreprises/connexion",
      "en": "/corporate/login",
      "es": "/empresas/iniciar-sesion",
      "de": "/unternehmen/anmelden",
      "nl": "/bedrijven/inloggen"
    },
    "index": {
      "fr": "/",
      "en": "/",
      "es": "/",
      "de": "/",
      "nl": "/"
    },
    "contact/index": {
      "fr": "/contact",
      "en": "/contact",
      "es": "/contacto",
      "de": "/kontakt",
      "nl": "/contacten"
    },
    "contact/form/[uid]": {
      "fr": "/contact/[uid]",
      "en": "/contact/[uid]",
      "es": "/contacto/[uid]",
      "de": "/kontakt/[uid]",
      "nl": "/contacten/[uid]"
    },
    "offers/index": {
      "fr": "/assurances",
      "en": "/plans",
      "es": "/seguros",
      "de": "/versicherungen",
      "nl": "/verzekeringen"
    },
    "guide/index": {
      "fr": "/assurance/guide",
      "en": "/insurance/guide",
      "es": "/seguro/guia",
      "de": "/versicherungen/guide",
      "nl": "/verzekeringen/gids"
    },
    "guide/[uid]": {
      "fr": "/assurance/guide/[uid]",
      "en": "/insurance/guide/[uid]",
      "es": "/seguro/guia/[uid]",
      "de": "/versicherungen/guide/[uid]",
      "nl": "/verzekeringen/gids/[uid]"
    },
    "[offer]/guide/index": {
      "fr": "/[offer]/guide",
      "en": "/[offer]/guide",
      "es": "/[offer]/guia",
      "de": "/[offer]/guide",
      "nl": "/[offer]/gids"
    },
    "[offer]/guide/[uid]": {
      "fr": "/[offer]/guide/[uid]",
      "en": "/[offer]/guide/[uid]",
      "es": "/[offer]/guia/[uid]",
      "de": "/[offer]/guide/[uid]",
      "nl": "/[offer]/gids/[uid]"
    },
    "guides-sitemap/index": {
      "fr": "/tous-nos-guides",
      "en": "/all-our-guides",
      "es": "/todas-nuestras-guias",
      "de": "/alle-unsere-guides",
      "nl": "/alle-gidsen"
    },
    "taxonomy/index": {
      "fr": "/guides-thematiques",
      "en": "/guides-thematiques",
      "es": "/guides-thematiques",
      "de": "/guides-thematiques",
      "nl": "/guides-thematiques"
    },
    "taxonomy/[uid]": {
      "fr": "/guides/[uid]",
      "en": "/guides/[uid]",
      "es": "/guias/[uid]",
      "de": "/leïtfaden/[uid]",
      "nl": "/gidsen/[uid]"
    },
    "guides-themes/index": {
      "fr": "/guides",
      "en": "/guides",
      "es": "/guias",
      "de": "/leïtfaden",
      "nl": "/gidsen"
    },
    "[offer]/[product]/index": {
      "fr": "/[offer]/[product]/",
      "en": "/[offer]/[product]/",
      "es": "/[offer]/[product]/",
      "de": "/[offer]/[product]/",
      "nl": "/[offer]/[product]/"
    },
    "[offer]/[product]/[form]/index": {
      "fr": "/[offer]/[product]/[form]",
      "en": "/[offer]/[product]/[form]",
      "es": "/[offer]/[product]/[form]",
      "de": "/[offer]/[product]/[form]",
      "nl": "/[offer]/[product]/[form]"
    },
    "support/[uid]/index": {
      "fr": "/aide/[uid]",
      "en": "/support/[uid]",
      "es": "/soporte/[uid]",
      "de": "/hilfe/[uid]",
      "nl": "/ondersteuning/[uid]"
    },
    "support/[uid]/[child]/index": {
      "fr": "/aide/[uid]/[child]",
      "en": "/support/[uid]/[child]",
      "es": "/soporte/[uid]/[child]",
      "de": "/hilfe/[uid]/[child]",
      "nl": "/ondersteuning/[uid]/[child]"
    },
    "services/[uid]/index": {
      "fr": "/services/[uid]",
      "en": "/services/[uid]",
      "es": "/servicios/[uid]",
      "de": "/serviceleistungen/[uid]",
      "nl": "/diensten/[uid]"
    },
    "services/[uid]/[child]/index": {
      "fr": "/services/[uid]/[child]",
      "en": "/services/[uid]/[child]",
      "es": "/servicios/[uid]/[child]",
      "de": "/serviceleistungen/[uid]/[child]",
      "nl": "/diensten/[uid]/[child]"
    },
    "legal/[uid]/index": {
      "fr": "/legal/[uid]",
      "en": "/legal/[uid]",
      "es": "/legal/[uid]",
      "de": "/legal/[uid]",
      "nl": "/legal/[uid]"
    },
    "legal/[uid]/[child]/index": {
      "fr": "/legal/[uid]/[child]",
      "en": "/legal/[uid]/[child]",
      "es": "/legal/[uid]/[child]",
      "de": "/legal/[uid]/[child]",
      "nl": "/legal/[uid]/[child]"
    },
    "about/[uid]/index": {
      "fr": "/a-propos/[uid]",
      "en": "/about-april/[uid]",
      "es": "/acerca-de-april/[uid]",
      "de": "/ueber-april/[uid]",
      "nl": "/over-april/[uid]"
    },
    "about/[uid]/[child]/index": {
      "fr": "/a-propos/[uid]/[child]",
      "en": "/about-april/[uid]/[child]",
      "es": "/acerca-de-april/[uid]/[child]",
      "de": "/ueber-april/[uid]/[child]",
      "nl": "/over-april/[uid]/[child]"
    },
    "about/news/index": {
      "fr": "/a-propos/actualites",
      "en": "/about-april/news",
      "es": "/acerca-de-april/noticias",
      "de": "/ueber-april/neuigkeiten",
      "nl": "/over-april/nieuws"
    },
    "about/news/[article]/index": {
      "fr": "/a-propos/actualites/[article]",
      "en": "/about-april/news/[article]",
      "es": "/acerca-de-april/noticias/[article]",
      "de": "/ueber-april/neuigkeiten/[article]",
      "nl": "/over-april/nieuws/[article]"
    },
    "support/faq/faqs": {
      "fr": "/aide/faq",
      "en": "/support/faq",
      "es": "/soporte/preguntas-frecuentes",
      "de": "/hilfe/haeufig-gestellte-fragen",
      "nl": "/ondersteuning/veel-gestelde-vragen"
    },
    "support/faq/[faq]/index": {
      "fr": "/aide/faq/[faq]",
      "en": "/support/faq/[faq]",
      "es": "/soporte/preguntas-frecuentes/[faq]",
      "de": "/hilfe/haeufig-gestellte-fragen/[faq]",
      "nl": "/ondersteuning/veel-gestelde-vragen/[faq]"
    },
    "regions/destinations": {
      "fr": "/destinations",
      "en": "/destinations",
      "es": "/destinos",
      "de": "/ziellaender",
      "nl": "/destinations"
    },
    "regions/[region]/index": {
      "fr": "/destinations/[region]",
      "en": "/destinations/[region]",
      "es": "/destinos/[region]",
      "de": "/ziellaender/[region]",
      "nl": "/destinations/[region]"
    },
    "regions/[region]/[country]/index": {
      "fr": "/destinations/[region]/[country]",
      "en": "/destinations/[region]/[country]",
      "es": "/destinos/[region]/[country]",
      "de": "/ziellaender/[region]/[country]",
      "nl": "/destinations/[region]/[country]"
    },
    "landing/[uid]": {
      "fr": "/landing/[uid]",
      "en": "/landing/[uid]",
      "es": "/landing/[uid]",
      "de": "/landing/[uid]",
      "nl": "/landing/[uid]"
    },
    "login/index": {
      "fr": "/connexion",
      "en": "/login",
      "es": "/iniciar-sesion",
      "de": "/anmelden",
      "nl": "/inloggen"
    },
    "slice_simulator": {},
    "api": {}
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "fr",
    "language": "fr-fr",
    "files": [
      {
        "path": "/vercel/path0/public/locales/shared.js"
      },
      {
        "path": "/vercel/path0/public/locales/fr/fr.common.json"
      },
      {
        "path": "/vercel/path0/public/locales/fr/fr.formsFields.json"
      },
      {
        "path": "/vercel/path0/public/locales/fr/fr.lisa.json"
      }
    ],
    "corporate": "entreprises"
  },
  {
    "code": "es",
    "language": "es-es",
    "files": [
      {
        "path": "/vercel/path0/public/locales/shared.js"
      },
      {
        "path": "/vercel/path0/public/locales/es/es.common.json"
      },
      {
        "path": "/vercel/path0/public/locales/es/es.formsFields.json"
      },
      {
        "path": "/vercel/path0/public/locales/es/es.lisa.json"
      }
    ],
    "corporate": "empresas"
  },
  {
    "code": "de",
    "language": "de-de",
    "files": [
      {
        "path": "/vercel/path0/public/locales/shared.js"
      },
      {
        "path": "/vercel/path0/public/locales/de/de.common.json"
      },
      {
        "path": "/vercel/path0/public/locales/de/de.formsFields.json"
      },
      {
        "path": "/vercel/path0/public/locales/de/de.lisa.json"
      }
    ],
    "corporate": "unternehmen"
  },
  {
    "code": "nl",
    "language": "nl-nl",
    "files": [
      {
        "path": "/vercel/path0/public/locales/shared.js"
      },
      {
        "path": "/vercel/path0/public/locales/nl/nl.common.json"
      },
      {
        "path": "/vercel/path0/public/locales/nl/nl.formsFields.json"
      },
      {
        "path": "/vercel/path0/public/locales/nl/nl.lisa.json"
      }
    ],
    "corporate": "bedrijven"
  },
  {
    "code": "en",
    "language": "en-gb",
    "files": [
      {
        "path": "/vercel/path0/public/locales/shared.js"
      },
      {
        "path": "/vercel/path0/public/locales/en/en.common.json"
      },
      {
        "path": "/vercel/path0/public/locales/en/en.formsFields.json"
      },
      {
        "path": "/vercel/path0/public/locales/en/en.lisa.json"
      }
    ],
    "corporate": "corporate"
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
