// since migration to Nuxt3, the i18n module is adding some meta tags that are not needed
// for some ununderstable reason, this feature is enabled: https://i18n.nuxtjs.org/docs/v8/guide/seo#feature-details
// after a first try to twerk the configuration for I18n module (https://github.com/AprilDirect/april.international/pull/951),
// here is a desperate attempt to remove the meta tags from the head tag
//
// this plugin removes the related tags from the head client side

const removeTags = () => {
  const metas = document.head.querySelectorAll(
    'link[id^="i18n-"], meta[id^="i18n-og"]',
  );

  metas.forEach((el) => el.remove());
};

// eslint-disable-next-line no-undef
export default defineNuxtPlugin((nuxtApp) => {
  [1000, 2000, 4000, 8000].forEach((time) => {
    setTimeout(() => {
      removeTags();
    }, time);
  });

  removeTags();
});
