import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as PrismicPreviewJ01p9R2IH8Meta } from "/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue?macro=true";
import { default as indexxUwcwgFl5DMeta } from "/vercel/path0/pages/[offer]/[product]/[form]/index.vue?macro=true";
import { default as indexle84UMO6fnMeta } from "/vercel/path0/pages/[offer]/[product]/index.vue?macro=true";
import { default as _91uid_93ZU3D2rvtz4Meta } from "/vercel/path0/pages/[offer]/guide/[uid].vue?macro=true";
import { default as indexLdosvJhEb9Meta } from "/vercel/path0/pages/[offer]/guide/index.vue?macro=true";
import { default as indexHyaWW18oCUMeta } from "/vercel/path0/pages/[offer]/index.vue?macro=true";
import { default as indexJQLvO9gCApMeta } from "/vercel/path0/pages/about/[uid]/[child]/index.vue?macro=true";
import { default as indexJ7NvakAmVHMeta } from "/vercel/path0/pages/about/[uid]/index.vue?macro=true";
import { default as indexTbMzRs5PtAMeta } from "/vercel/path0/pages/about/news/[article]/index.vue?macro=true";
import { default as indexV3i7WzHaGVMeta } from "/vercel/path0/pages/about/news/index.vue?macro=true";
import { default as _91uid_93LyTb2pnMe1Meta } from "/vercel/path0/pages/contact/form/[uid].vue?macro=true";
import { default as indexDBbnZWdxZ3Meta } from "/vercel/path0/pages/contact/index.vue?macro=true";
import { default as indexfHvswIkBR3Meta } from "/vercel/path0/pages/corporate/[offer]/[product]/[form]/index.vue?macro=true";
import { default as indexXJ5sf8noQFMeta } from "/vercel/path0/pages/corporate/[offer]/[product]/index.vue?macro=true";
import { default as _91uid_93ompqXRFYN4Meta } from "/vercel/path0/pages/corporate/[offer]/guide/[uid].vue?macro=true";
import { default as indexpyMmCRwTV9Meta } from "/vercel/path0/pages/corporate/[offer]/guide/index.vue?macro=true";
import { default as indexUhIBLHRHXoMeta } from "/vercel/path0/pages/corporate/[offer]/index.vue?macro=true";
import { default as indexyQabYZE4MjMeta } from "/vercel/path0/pages/corporate/about/[uid]/[child]/index.vue?macro=true";
import { default as indexO02kszQa2vMeta } from "/vercel/path0/pages/corporate/about/[uid]/index.vue?macro=true";
import { default as indexAe4eJbrVe5Meta } from "/vercel/path0/pages/corporate/about/news/[article]/index.vue?macro=true";
import { default as indexQtw76d54RMMeta } from "/vercel/path0/pages/corporate/about/news/index.vue?macro=true";
import { default as _91uid_93wTJAJUmDeUMeta } from "/vercel/path0/pages/corporate/contact/form/[uid].vue?macro=true";
import { default as indexy0HEREaC2JMeta } from "/vercel/path0/pages/corporate/contact/index.vue?macro=true";
import { default as _91uid_932yZcMKDPgLMeta } from "/vercel/path0/pages/corporate/guide/[uid].vue?macro=true";
import { default as indexRwvtTqKf5RMeta } from "/vercel/path0/pages/corporate/guide/index.vue?macro=true";
import { default as indexHvWpsAwKQxMeta } from "/vercel/path0/pages/corporate/guides-sitemap/index.vue?macro=true";
import { default as indexj0Fk6C4FsCMeta } from "/vercel/path0/pages/corporate/guides-themes/index.vue?macro=true";
import { default as indexPH7uIFbeDtMeta } from "/vercel/path0/pages/corporate/index.vue?macro=true";
import { default as _91uid_93qtDyNZqW08Meta } from "/vercel/path0/pages/corporate/landing/[uid].vue?macro=true";
import { default as indexiq058rdgYyMeta } from "/vercel/path0/pages/corporate/legal/[uid]/[child]/index.vue?macro=true";
import { default as index2Uy3TDVmpmMeta } from "/vercel/path0/pages/corporate/legal/[uid]/index.vue?macro=true";
import { default as indexzzM6nxScJmMeta } from "/vercel/path0/pages/corporate/login/index.vue?macro=true";
import { default as indexNcVT3K6fcaMeta } from "/vercel/path0/pages/corporate/regions/[region]/[country]/index.vue?macro=true";
import { default as indexofaIJy11uqMeta } from "/vercel/path0/pages/corporate/regions/[region]/index.vue?macro=true";
import { default as destinationsQhy7s6Xt1tMeta } from "/vercel/path0/pages/corporate/regions/destinations.vue?macro=true";
import { default as indexPf7yykGA3RMeta } from "/vercel/path0/pages/corporate/services/[uid]/[child]/index.vue?macro=true";
import { default as indexzRsjp3CoEXMeta } from "/vercel/path0/pages/corporate/services/[uid]/index.vue?macro=true";
import { default as indexoPg3GdQKZQMeta } from "/vercel/path0/pages/corporate/support/[uid]/[child]/index.vue?macro=true";
import { default as indexSZgKLZNOxyMeta } from "/vercel/path0/pages/corporate/support/[uid]/index.vue?macro=true";
import { default as indexOkHVUYnPXbMeta } from "/vercel/path0/pages/corporate/support/faq/[faq]/index.vue?macro=true";
import { default as faqs96ljG2mhz4Meta } from "/vercel/path0/pages/corporate/support/faq/faqs.vue?macro=true";
import { default as _91uid_93XCMtaWrHO6Meta } from "/vercel/path0/pages/corporate/taxonomy/[uid].vue?macro=true";
import { default as indexBEHdXdmG2EMeta } from "/vercel/path0/pages/corporate/taxonomy/index.vue?macro=true";
import { default as _91uid_93iVKgohnQsBMeta } from "/vercel/path0/pages/guide/[uid].vue?macro=true";
import { default as indexMfxqfHtaOPMeta } from "/vercel/path0/pages/guide/index.vue?macro=true";
import { default as indexksh471krAWMeta } from "/vercel/path0/pages/guides-sitemap/index.vue?macro=true";
import { default as indexS57GEBr06gMeta } from "/vercel/path0/pages/guides-themes/index.vue?macro=true";
import { default as _91uid_93aLiC6W9cvWMeta } from "/vercel/path0/pages/landing/[uid].vue?macro=true";
import { default as indexAeLfXONsmCMeta } from "/vercel/path0/pages/legal/[uid]/[child]/index.vue?macro=true";
import { default as indexZrXnKuw1g6Meta } from "/vercel/path0/pages/legal/[uid]/index.vue?macro=true";
import { default as indexoOBDY93e9EMeta } from "/vercel/path0/pages/login/index.vue?macro=true";
import { default as index7pp5WpoXdvMeta } from "/vercel/path0/pages/offers/index.vue?macro=true";
import { default as indexxLOXzjb2AWMeta } from "/vercel/path0/pages/regions/[region]/[country]/index.vue?macro=true";
import { default as indexYlEDwu1WXIMeta } from "/vercel/path0/pages/regions/[region]/index.vue?macro=true";
import { default as destinationsg9YlIY3SFVMeta } from "/vercel/path0/pages/regions/destinations.vue?macro=true";
import { default as indexsr9YBMlmUgMeta } from "/vercel/path0/pages/services/[uid]/[child]/index.vue?macro=true";
import { default as indexLX8XjsONnGMeta } from "/vercel/path0/pages/services/[uid]/index.vue?macro=true";
import { default as slice_45simulatorXNBTl3WVLzMeta } from "/vercel/path0/pages/slice-simulator.vue?macro=true";
import { default as indexrkpGtFoaGnMeta } from "/vercel/path0/pages/support/[uid]/[child]/index.vue?macro=true";
import { default as indexk7LDUKfPjbMeta } from "/vercel/path0/pages/support/[uid]/index.vue?macro=true";
import { default as indexE7ZfUBPLbqMeta } from "/vercel/path0/pages/support/faq/[faq]/index.vue?macro=true";
import { default as faqsHiLYr7qrBLMeta } from "/vercel/path0/pages/support/faq/faqs.vue?macro=true";
import { default as _91uid_93clK18XMx17Meta } from "/vercel/path0/pages/taxonomy/[uid].vue?macro=true";
import { default as index8dZ6MbPFNSMeta } from "/vercel/path0/pages/taxonomy/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "prismic-preview___fr",
    path: "/fr/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue").then(m => m.default || m)
  },
  {
    name: "prismic-preview___es",
    path: "/es/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue").then(m => m.default || m)
  },
  {
    name: "prismic-preview___de",
    path: "/de/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue").then(m => m.default || m)
  },
  {
    name: "prismic-preview___nl",
    path: "/nl/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue").then(m => m.default || m)
  },
  {
    name: "prismic-preview___en",
    path: "/en/preview",
    component: () => import("/vercel/path0/node_modules/@nuxtjs/prismic/dist/runtime/PrismicPreview.vue").then(m => m.default || m)
  },
  {
    name: "offer-product-form___fr",
    path: "/fr/:offer()/:product()/:form()",
    component: () => import("/vercel/path0/pages/[offer]/[product]/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-product-form___es",
    path: "/es/:offer()/:product()/:form()",
    component: () => import("/vercel/path0/pages/[offer]/[product]/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-product-form___de",
    path: "/de/:offer()/:product()/:form()",
    component: () => import("/vercel/path0/pages/[offer]/[product]/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-product-form___nl",
    path: "/nl/:offer()/:product()/:form()",
    component: () => import("/vercel/path0/pages/[offer]/[product]/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-product-form___en",
    path: "/en/:offer()/:product()/:form()",
    component: () => import("/vercel/path0/pages/[offer]/[product]/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-product___fr",
    path: "/fr/:offer()/:product()",
    component: () => import("/vercel/path0/pages/[offer]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-product___es",
    path: "/es/:offer()/:product()",
    component: () => import("/vercel/path0/pages/[offer]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-product___de",
    path: "/de/:offer()/:product()",
    component: () => import("/vercel/path0/pages/[offer]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-product___nl",
    path: "/nl/:offer()/:product()",
    component: () => import("/vercel/path0/pages/[offer]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-product___en",
    path: "/en/:offer()/:product()",
    component: () => import("/vercel/path0/pages/[offer]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-guide-uid___fr",
    path: "/fr/:offer()/guide/:uid()",
    component: () => import("/vercel/path0/pages/[offer]/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "offer-guide-uid___es",
    path: "/es/:offer()/guia/:uid()",
    component: () => import("/vercel/path0/pages/[offer]/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "offer-guide-uid___de",
    path: "/de/:offer()/guide/:uid()",
    component: () => import("/vercel/path0/pages/[offer]/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "offer-guide-uid___nl",
    path: "/nl/:offer()/gids/:uid()",
    component: () => import("/vercel/path0/pages/[offer]/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "offer-guide-uid___en",
    path: "/en/:offer()/guide/:uid()",
    component: () => import("/vercel/path0/pages/[offer]/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "offer-guide___fr",
    path: "/fr/:offer()/guide",
    component: () => import("/vercel/path0/pages/[offer]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-guide___es",
    path: "/es/:offer()/guia",
    component: () => import("/vercel/path0/pages/[offer]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-guide___de",
    path: "/de/:offer()/guide",
    component: () => import("/vercel/path0/pages/[offer]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-guide___nl",
    path: "/nl/:offer()/gids",
    component: () => import("/vercel/path0/pages/[offer]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "offer-guide___en",
    path: "/en/:offer()/guide",
    component: () => import("/vercel/path0/pages/[offer]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "offer___fr",
    path: "/fr/:offer()",
    component: () => import("/vercel/path0/pages/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer___es",
    path: "/es/:offer()",
    component: () => import("/vercel/path0/pages/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer___de",
    path: "/de/:offer()",
    component: () => import("/vercel/path0/pages/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer___nl",
    path: "/nl/:offer()",
    component: () => import("/vercel/path0/pages/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: "offer___en",
    path: "/en/:offer()",
    component: () => import("/vercel/path0/pages/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-uid-child___fr",
    path: "/fr/a-propos/:uid()/:child()",
    component: () => import("/vercel/path0/pages/about/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-uid-child___es",
    path: "/es/acerca-de-april/:uid()/:child()",
    component: () => import("/vercel/path0/pages/about/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-uid-child___de",
    path: "/de/ueber-april/:uid()/:child()",
    component: () => import("/vercel/path0/pages/about/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-uid-child___nl",
    path: "/nl/over-april/:uid()/:child()",
    component: () => import("/vercel/path0/pages/about/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-uid-child___en",
    path: "/en/about-april/:uid()/:child()",
    component: () => import("/vercel/path0/pages/about/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-uid___fr",
    path: "/fr/a-propos/:uid()",
    component: () => import("/vercel/path0/pages/about/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-uid___es",
    path: "/es/acerca-de-april/:uid()",
    component: () => import("/vercel/path0/pages/about/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-uid___de",
    path: "/de/ueber-april/:uid()",
    component: () => import("/vercel/path0/pages/about/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-uid___nl",
    path: "/nl/over-april/:uid()",
    component: () => import("/vercel/path0/pages/about/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-uid___en",
    path: "/en/about-april/:uid()",
    component: () => import("/vercel/path0/pages/about/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-news-article___fr",
    path: "/fr/a-propos/actualites/:article()",
    component: () => import("/vercel/path0/pages/about/news/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-news-article___es",
    path: "/es/acerca-de-april/noticias/:article()",
    component: () => import("/vercel/path0/pages/about/news/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-news-article___de",
    path: "/de/ueber-april/neuigkeiten/:article()",
    component: () => import("/vercel/path0/pages/about/news/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-news-article___nl",
    path: "/nl/over-april/nieuws/:article()",
    component: () => import("/vercel/path0/pages/about/news/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-news-article___en",
    path: "/en/about-april/news/:article()",
    component: () => import("/vercel/path0/pages/about/news/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: "about-news___fr",
    path: "/fr/a-propos/actualites",
    component: () => import("/vercel/path0/pages/about/news/index.vue").then(m => m.default || m)
  },
  {
    name: "about-news___es",
    path: "/es/acerca-de-april/noticias",
    component: () => import("/vercel/path0/pages/about/news/index.vue").then(m => m.default || m)
  },
  {
    name: "about-news___de",
    path: "/de/ueber-april/neuigkeiten",
    component: () => import("/vercel/path0/pages/about/news/index.vue").then(m => m.default || m)
  },
  {
    name: "about-news___nl",
    path: "/nl/over-april/nieuws",
    component: () => import("/vercel/path0/pages/about/news/index.vue").then(m => m.default || m)
  },
  {
    name: "about-news___en",
    path: "/en/about-april/news",
    component: () => import("/vercel/path0/pages/about/news/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-form-uid___fr",
    path: "/fr/contact/:uid()",
    component: () => import("/vercel/path0/pages/contact/form/[uid].vue").then(m => m.default || m)
  },
  {
    name: "contact-form-uid___es",
    path: "/es/contacto/:uid()",
    component: () => import("/vercel/path0/pages/contact/form/[uid].vue").then(m => m.default || m)
  },
  {
    name: "contact-form-uid___de",
    path: "/de/kontakt/:uid()",
    component: () => import("/vercel/path0/pages/contact/form/[uid].vue").then(m => m.default || m)
  },
  {
    name: "contact-form-uid___nl",
    path: "/nl/contacten/:uid()",
    component: () => import("/vercel/path0/pages/contact/form/[uid].vue").then(m => m.default || m)
  },
  {
    name: "contact-form-uid___en",
    path: "/en/contact/:uid()",
    component: () => import("/vercel/path0/pages/contact/form/[uid].vue").then(m => m.default || m)
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___es",
    path: "/es/contacto",
    component: () => import("/vercel/path0/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/vercel/path0/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___nl",
    path: "/nl/contacten",
    component: () => import("/vercel/path0/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/vercel/path0/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-product-form___fr",
    path: "/fr/entreprises/:offer()/:product()/:form()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/[product]/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-product-form___es",
    path: "/es/empresas/:offer()/:product()/:form()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/[product]/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-product-form___de",
    path: "/de/unternehmen/:offer()/:product()/:form()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/[product]/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-product-form___nl",
    path: "/nl/bedrijven/:offer()/:product()/:form()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/[product]/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-product-form___en",
    path: "/en/corporate/:offer()/:product()/:form()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/[product]/[form]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-product___fr",
    path: "/fr/entreprises/:offer()/:product()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-product___es",
    path: "/es/empresas/:offer()/:product()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-product___de",
    path: "/de/unternehmen/:offer()/:product()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-product___nl",
    path: "/nl/bedrijven/:offer()/:product()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-product___en",
    path: "/en/corporate/:offer()/:product()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-guide-uid___fr",
    path: "/fr/entreprises/:offer()/guide/:uid()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-guide-uid___es",
    path: "/es/empresas/:offer()/guia/:uid()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-guide-uid___de",
    path: "/de/unternehmen/:offer()/guide/:uid()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-guide-uid___nl",
    path: "/nl/bedrijven/:offer()/gids/:uid()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-guide-uid___en",
    path: "/en/corporate/:offer()/guide/:uid()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-guide___fr",
    path: "/fr/entreprises/:offer()/guide",
    component: () => import("/vercel/path0/pages/corporate/[offer]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-guide___es",
    path: "/es/empresas/:offer()/guia",
    component: () => import("/vercel/path0/pages/corporate/[offer]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-guide___de",
    path: "/de/unternehmen/:offer()/guide",
    component: () => import("/vercel/path0/pages/corporate/[offer]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-guide___nl",
    path: "/nl/bedrijven/:offer()/gids",
    component: () => import("/vercel/path0/pages/corporate/[offer]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer-guide___en",
    path: "/en/corporate/:offer()/guide",
    component: () => import("/vercel/path0/pages/corporate/[offer]/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer___fr",
    path: "/fr/entreprises/:offer()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer___es",
    path: "/es/empresas/:offer()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer___de",
    path: "/de/unternehmen/:offer()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer___nl",
    path: "/nl/bedrijven/:offer()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-offer___en",
    path: "/en/corporate/:offer()",
    component: () => import("/vercel/path0/pages/corporate/[offer]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-uid-child___fr",
    path: "/fr/entreprises/a-propos/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/about/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-uid-child___es",
    path: "/es/empresas/acerca-de-april/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/about/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-uid-child___de",
    path: "/de/unternehmen/ueber-april/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/about/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-uid-child___nl",
    path: "/nl/bedrijven/over-april/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/about/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-uid-child___en",
    path: "/en/corporate/about-april/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/about/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-uid___fr",
    path: "/fr/entreprises/a-propos/:uid()",
    component: () => import("/vercel/path0/pages/corporate/about/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-uid___es",
    path: "/es/empresas/acerca-de-april/:uid()",
    component: () => import("/vercel/path0/pages/corporate/about/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-uid___de",
    path: "/de/unternehmen/ueber-april/:uid()",
    component: () => import("/vercel/path0/pages/corporate/about/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-uid___nl",
    path: "/nl/bedrijven/over-april/:uid()",
    component: () => import("/vercel/path0/pages/corporate/about/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-uid___en",
    path: "/en/corporate/about-april/:uid()",
    component: () => import("/vercel/path0/pages/corporate/about/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-news-article___fr",
    path: "/fr/entreprises/a-propos/actualites/:article()",
    component: () => import("/vercel/path0/pages/corporate/about/news/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-news-article___es",
    path: "/es/empresas/acerca-de-april/noticias/:article()",
    component: () => import("/vercel/path0/pages/corporate/about/news/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-news-article___de",
    path: "/de/unternehmen/ueber-april/neuigkeiten/:article()",
    component: () => import("/vercel/path0/pages/corporate/about/news/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-news-article___nl",
    path: "/nl/bedrijven/over-april/nieuws/:article()",
    component: () => import("/vercel/path0/pages/corporate/about/news/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-news-article___en",
    path: "/en/corporate/about-april/news/:article()",
    component: () => import("/vercel/path0/pages/corporate/about/news/[article]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-news___fr",
    path: "/fr/entreprises/a-propos/actualites",
    component: () => import("/vercel/path0/pages/corporate/about/news/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-news___es",
    path: "/es/empresas/acerca-de-april/noticias",
    component: () => import("/vercel/path0/pages/corporate/about/news/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-news___de",
    path: "/de/unternehmen/ueber-april/neuigkeiten",
    component: () => import("/vercel/path0/pages/corporate/about/news/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-news___nl",
    path: "/nl/bedrijven/over-april/nieuws",
    component: () => import("/vercel/path0/pages/corporate/about/news/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-about-news___en",
    path: "/en/corporate/about-april/news",
    component: () => import("/vercel/path0/pages/corporate/about/news/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-contact-form-uid___fr",
    path: "/fr/entreprises/contact/:uid()",
    component: () => import("/vercel/path0/pages/corporate/contact/form/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-contact-form-uid___es",
    path: "/es/empresas/contacto/:uid()",
    component: () => import("/vercel/path0/pages/corporate/contact/form/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-contact-form-uid___de",
    path: "/de/unternehmen/kontakt/:uid()",
    component: () => import("/vercel/path0/pages/corporate/contact/form/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-contact-form-uid___nl",
    path: "/nl/bedrijven/contacten/:uid()",
    component: () => import("/vercel/path0/pages/corporate/contact/form/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-contact-form-uid___en",
    path: "/en/corporate/contact/:uid()",
    component: () => import("/vercel/path0/pages/corporate/contact/form/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-contact___fr",
    path: "/fr/entreprises/contact",
    component: () => import("/vercel/path0/pages/corporate/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-contact___es",
    path: "/es/empresas/contacto",
    component: () => import("/vercel/path0/pages/corporate/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-contact___de",
    path: "/de/unternehmen/kontakt",
    component: () => import("/vercel/path0/pages/corporate/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-contact___nl",
    path: "/nl/bedrijven/contacten",
    component: () => import("/vercel/path0/pages/corporate/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-contact___en",
    path: "/en/corporate/contact",
    component: () => import("/vercel/path0/pages/corporate/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guide-uid___fr",
    path: "/fr/entreprises/assurance/guide/:uid()",
    component: () => import("/vercel/path0/pages/corporate/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-guide-uid___es",
    path: "/es/empresas/seguro/guia/:uid()",
    component: () => import("/vercel/path0/pages/corporate/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-guide-uid___de",
    path: "/de/unternehmen/versicherungen/guide/:uid()",
    component: () => import("/vercel/path0/pages/corporate/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-guide-uid___nl",
    path: "/nl/bedrijven/verzekeringen/gids/:uid()",
    component: () => import("/vercel/path0/pages/corporate/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-guide-uid___en",
    path: "/en/corporate/insurance/guide/:uid()",
    component: () => import("/vercel/path0/pages/corporate/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-guide___fr",
    path: "/fr/entreprises/assurance/guide",
    component: () => import("/vercel/path0/pages/corporate/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guide___es",
    path: "/es/empresas/seguro/guia",
    component: () => import("/vercel/path0/pages/corporate/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guide___de",
    path: "/de/unternehmen/versicherungen/guide",
    component: () => import("/vercel/path0/pages/corporate/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guide___nl",
    path: "/nl/bedrijven/verzekeringen/gids",
    component: () => import("/vercel/path0/pages/corporate/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guide___en",
    path: "/en/corporate/insurance/guide",
    component: () => import("/vercel/path0/pages/corporate/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guides-sitemap___fr",
    path: "/fr/entreprises/tous-nos-guides",
    component: () => import("/vercel/path0/pages/corporate/guides-sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guides-sitemap___es",
    path: "/es/empresas/todas-nuestras-guias",
    component: () => import("/vercel/path0/pages/corporate/guides-sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guides-sitemap___de",
    path: "/de/unternehmen/alle-unsere-guides",
    component: () => import("/vercel/path0/pages/corporate/guides-sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guides-sitemap___nl",
    path: "/nl/bedrijven/alle-gidsen",
    component: () => import("/vercel/path0/pages/corporate/guides-sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guides-sitemap___en",
    path: "/en/corporate/all-our-guides",
    component: () => import("/vercel/path0/pages/corporate/guides-sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guides-themes___fr",
    path: "/fr/entreprises/guides",
    component: () => import("/vercel/path0/pages/corporate/guides-themes/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guides-themes___es",
    path: "/es/empresas/guias",
    component: () => import("/vercel/path0/pages/corporate/guides-themes/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guides-themes___de",
    path: "/de/unternehmen/le%C3%AFtfaden",
    component: () => import("/vercel/path0/pages/corporate/guides-themes/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guides-themes___nl",
    path: "/nl/bedrijven/gidsen",
    component: () => import("/vercel/path0/pages/corporate/guides-themes/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-guides-themes___en",
    path: "/en/corporate/guides",
    component: () => import("/vercel/path0/pages/corporate/guides-themes/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate___fr",
    path: "/fr/entreprises",
    component: () => import("/vercel/path0/pages/corporate/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate___es",
    path: "/es/empresas",
    component: () => import("/vercel/path0/pages/corporate/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate___de",
    path: "/de/unternehmen",
    component: () => import("/vercel/path0/pages/corporate/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate___nl",
    path: "/nl/bedrijven",
    component: () => import("/vercel/path0/pages/corporate/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate___en",
    path: "/en/corporate",
    component: () => import("/vercel/path0/pages/corporate/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-landing-uid___fr",
    path: "/fr/entreprises/landing/:uid()",
    component: () => import("/vercel/path0/pages/corporate/landing/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-landing-uid___es",
    path: "/es/empresas/landing/:uid()",
    component: () => import("/vercel/path0/pages/corporate/landing/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-landing-uid___de",
    path: "/de/unternehmen/landing/:uid()",
    component: () => import("/vercel/path0/pages/corporate/landing/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-landing-uid___nl",
    path: "/nl/bedrijven/landing/:uid()",
    component: () => import("/vercel/path0/pages/corporate/landing/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-landing-uid___en",
    path: "/en/corporate/landing/:uid()",
    component: () => import("/vercel/path0/pages/corporate/landing/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-legal-uid-child___fr",
    path: "/fr/entreprises/legal/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/legal/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-legal-uid-child___es",
    path: "/es/empresas/legal/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/legal/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-legal-uid-child___de",
    path: "/de/unternehmen/legal/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/legal/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-legal-uid-child___nl",
    path: "/nl/bedrijven/legal/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/legal/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-legal-uid-child___en",
    path: "/en/corporate/legal/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/legal/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-legal-uid___fr",
    path: "/fr/entreprises/legal/:uid()",
    component: () => import("/vercel/path0/pages/corporate/legal/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-legal-uid___es",
    path: "/es/empresas/legal/:uid()",
    component: () => import("/vercel/path0/pages/corporate/legal/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-legal-uid___de",
    path: "/de/unternehmen/legal/:uid()",
    component: () => import("/vercel/path0/pages/corporate/legal/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-legal-uid___nl",
    path: "/nl/bedrijven/legal/:uid()",
    component: () => import("/vercel/path0/pages/corporate/legal/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-legal-uid___en",
    path: "/en/corporate/legal/:uid()",
    component: () => import("/vercel/path0/pages/corporate/legal/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-login___fr",
    path: "/fr/entreprises/connexion",
    component: () => import("/vercel/path0/pages/corporate/login/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-login___es",
    path: "/es/empresas/iniciar-sesion",
    component: () => import("/vercel/path0/pages/corporate/login/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-login___de",
    path: "/de/unternehmen/anmelden",
    component: () => import("/vercel/path0/pages/corporate/login/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-login___nl",
    path: "/nl/bedrijven/inloggen",
    component: () => import("/vercel/path0/pages/corporate/login/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-login___en",
    path: "/en/corporate/login",
    component: () => import("/vercel/path0/pages/corporate/login/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-region-country___fr",
    path: "/fr/entreprises/destinations/:region()/:country()",
    component: () => import("/vercel/path0/pages/corporate/regions/[region]/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-region-country___es",
    path: "/es/empresas/destinos/:region()/:country()",
    component: () => import("/vercel/path0/pages/corporate/regions/[region]/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-region-country___de",
    path: "/de/unternehmen/ziellaender/:region()/:country()",
    component: () => import("/vercel/path0/pages/corporate/regions/[region]/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-region-country___nl",
    path: "/nl/bedrijven/destinations/:region()/:country()",
    component: () => import("/vercel/path0/pages/corporate/regions/[region]/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-region-country___en",
    path: "/en/corporate/destinations/:region()/:country()",
    component: () => import("/vercel/path0/pages/corporate/regions/[region]/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-region___fr",
    path: "/fr/entreprises/destinations/:region()",
    component: () => import("/vercel/path0/pages/corporate/regions/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-region___es",
    path: "/es/empresas/destinos/:region()",
    component: () => import("/vercel/path0/pages/corporate/regions/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-region___de",
    path: "/de/unternehmen/ziellaender/:region()",
    component: () => import("/vercel/path0/pages/corporate/regions/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-region___nl",
    path: "/nl/bedrijven/destinations/:region()",
    component: () => import("/vercel/path0/pages/corporate/regions/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-region___en",
    path: "/en/corporate/destinations/:region()",
    component: () => import("/vercel/path0/pages/corporate/regions/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-destinations___fr",
    path: "/fr/entreprises/destinations",
    component: () => import("/vercel/path0/pages/corporate/regions/destinations.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-destinations___es",
    path: "/es/empresas/destinos",
    component: () => import("/vercel/path0/pages/corporate/regions/destinations.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-destinations___de",
    path: "/de/unternehmen/ziellaender",
    component: () => import("/vercel/path0/pages/corporate/regions/destinations.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-destinations___nl",
    path: "/nl/bedrijven/destinations",
    component: () => import("/vercel/path0/pages/corporate/regions/destinations.vue").then(m => m.default || m)
  },
  {
    name: "corporate-regions-destinations___en",
    path: "/en/corporate/destinations",
    component: () => import("/vercel/path0/pages/corporate/regions/destinations.vue").then(m => m.default || m)
  },
  {
    name: "corporate-services-uid-child___fr",
    path: "/fr/entreprises/services/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/services/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-services-uid-child___es",
    path: "/es/empresas/servicios/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/services/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-services-uid-child___de",
    path: "/de/unternehmen/serviceleistungen/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/services/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-services-uid-child___nl",
    path: "/nl/bedrijven/diensten/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/services/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-services-uid-child___en",
    path: "/en/corporate/services/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/services/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-services-uid___fr",
    path: "/fr/entreprises/services/:uid()",
    component: () => import("/vercel/path0/pages/corporate/services/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-services-uid___es",
    path: "/es/empresas/servicios/:uid()",
    component: () => import("/vercel/path0/pages/corporate/services/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-services-uid___de",
    path: "/de/unternehmen/serviceleistungen/:uid()",
    component: () => import("/vercel/path0/pages/corporate/services/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-services-uid___nl",
    path: "/nl/bedrijven/diensten/:uid()",
    component: () => import("/vercel/path0/pages/corporate/services/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-services-uid___en",
    path: "/en/corporate/services/:uid()",
    component: () => import("/vercel/path0/pages/corporate/services/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-uid-child___fr",
    path: "/fr/entreprises/aide/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/support/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-uid-child___es",
    path: "/es/empresas/soporte/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/support/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-uid-child___de",
    path: "/de/unternehmen/hilfe/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/support/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-uid-child___nl",
    path: "/nl/bedrijven/ondersteuning/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/support/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-uid-child___en",
    path: "/en/corporate/support/:uid()/:child()",
    component: () => import("/vercel/path0/pages/corporate/support/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-uid___fr",
    path: "/fr/entreprises/aide/:uid()",
    component: () => import("/vercel/path0/pages/corporate/support/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-uid___es",
    path: "/es/empresas/soporte/:uid()",
    component: () => import("/vercel/path0/pages/corporate/support/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-uid___de",
    path: "/de/unternehmen/hilfe/:uid()",
    component: () => import("/vercel/path0/pages/corporate/support/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-uid___nl",
    path: "/nl/bedrijven/ondersteuning/:uid()",
    component: () => import("/vercel/path0/pages/corporate/support/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-uid___en",
    path: "/en/corporate/support/:uid()",
    component: () => import("/vercel/path0/pages/corporate/support/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-faq-faq___fr",
    path: "/fr/entreprises/aide/faq/:faq()",
    component: () => import("/vercel/path0/pages/corporate/support/faq/[faq]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-faq-faq___es",
    path: "/es/empresas/soporte/preguntas-frecuentes/:faq()",
    component: () => import("/vercel/path0/pages/corporate/support/faq/[faq]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-faq-faq___de",
    path: "/de/unternehmen/hilfe/haeufig-gestellte-fragen/:faq()",
    component: () => import("/vercel/path0/pages/corporate/support/faq/[faq]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-faq-faq___nl",
    path: "/nl/bedrijven/ondersteuning/veel-gestelde-vragen/:faq()",
    component: () => import("/vercel/path0/pages/corporate/support/faq/[faq]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-faq-faq___en",
    path: "/en/corporate/support/faq/:faq()",
    component: () => import("/vercel/path0/pages/corporate/support/faq/[faq]/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-faq-faqs___fr",
    path: "/fr/entreprises/aide/faq",
    component: () => import("/vercel/path0/pages/corporate/support/faq/faqs.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-faq-faqs___es",
    path: "/es/empresas/soporte/preguntas-frecuentes",
    component: () => import("/vercel/path0/pages/corporate/support/faq/faqs.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-faq-faqs___de",
    path: "/de/unternehmen/hilfe/haeufig-gestellte-fragen",
    component: () => import("/vercel/path0/pages/corporate/support/faq/faqs.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-faq-faqs___nl",
    path: "/nl/bedrijven/ondersteuning/veel-gestelde-vragen",
    component: () => import("/vercel/path0/pages/corporate/support/faq/faqs.vue").then(m => m.default || m)
  },
  {
    name: "corporate-support-faq-faqs___en",
    path: "/en/corporate/support/faq",
    component: () => import("/vercel/path0/pages/corporate/support/faq/faqs.vue").then(m => m.default || m)
  },
  {
    name: "corporate-taxonomy-uid___fr",
    path: "/fr/entreprises/guides/:uid()",
    component: () => import("/vercel/path0/pages/corporate/taxonomy/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-taxonomy-uid___es",
    path: "/es/empresas/guias/:uid()",
    component: () => import("/vercel/path0/pages/corporate/taxonomy/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-taxonomy-uid___de",
    path: "/de/unternehmen/le%C3%AFtfaden/:uid()",
    component: () => import("/vercel/path0/pages/corporate/taxonomy/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-taxonomy-uid___nl",
    path: "/nl/bedrijven/gidsen/:uid()",
    component: () => import("/vercel/path0/pages/corporate/taxonomy/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-taxonomy-uid___en",
    path: "/en/corporate/guides/:uid()",
    component: () => import("/vercel/path0/pages/corporate/taxonomy/[uid].vue").then(m => m.default || m)
  },
  {
    name: "corporate-taxonomy___fr",
    path: "/fr/entreprises/guides-thematiques",
    component: () => import("/vercel/path0/pages/corporate/taxonomy/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-taxonomy___es",
    path: "/es/empresas/guides-thematiques",
    component: () => import("/vercel/path0/pages/corporate/taxonomy/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-taxonomy___de",
    path: "/de/unternehmen/guides-thematiques",
    component: () => import("/vercel/path0/pages/corporate/taxonomy/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-taxonomy___nl",
    path: "/nl/bedrijven/guides-thematiques",
    component: () => import("/vercel/path0/pages/corporate/taxonomy/index.vue").then(m => m.default || m)
  },
  {
    name: "corporate-taxonomy___en",
    path: "/en/corporate/guides-thematiques",
    component: () => import("/vercel/path0/pages/corporate/taxonomy/index.vue").then(m => m.default || m)
  },
  {
    name: "guide-uid___fr",
    path: "/fr/assurance/guide/:uid()",
    component: () => import("/vercel/path0/pages/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "guide-uid___es",
    path: "/es/seguro/guia/:uid()",
    component: () => import("/vercel/path0/pages/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "guide-uid___de",
    path: "/de/versicherungen/guide/:uid()",
    component: () => import("/vercel/path0/pages/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "guide-uid___nl",
    path: "/nl/verzekeringen/gids/:uid()",
    component: () => import("/vercel/path0/pages/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "guide-uid___en",
    path: "/en/insurance/guide/:uid()",
    component: () => import("/vercel/path0/pages/guide/[uid].vue").then(m => m.default || m)
  },
  {
    name: "guide___fr",
    path: "/fr/assurance/guide",
    component: () => import("/vercel/path0/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "guide___es",
    path: "/es/seguro/guia",
    component: () => import("/vercel/path0/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "guide___de",
    path: "/de/versicherungen/guide",
    component: () => import("/vercel/path0/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "guide___nl",
    path: "/nl/verzekeringen/gids",
    component: () => import("/vercel/path0/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "guide___en",
    path: "/en/insurance/guide",
    component: () => import("/vercel/path0/pages/guide/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-sitemap___fr",
    path: "/fr/tous-nos-guides",
    component: () => import("/vercel/path0/pages/guides-sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-sitemap___es",
    path: "/es/todas-nuestras-guias",
    component: () => import("/vercel/path0/pages/guides-sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-sitemap___de",
    path: "/de/alle-unsere-guides",
    component: () => import("/vercel/path0/pages/guides-sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-sitemap___nl",
    path: "/nl/alle-gidsen",
    component: () => import("/vercel/path0/pages/guides-sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-sitemap___en",
    path: "/en/all-our-guides",
    component: () => import("/vercel/path0/pages/guides-sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-themes___fr",
    path: "/fr/guides",
    component: () => import("/vercel/path0/pages/guides-themes/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-themes___es",
    path: "/es/guias",
    component: () => import("/vercel/path0/pages/guides-themes/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-themes___de",
    path: "/de/le%C3%AFtfaden",
    component: () => import("/vercel/path0/pages/guides-themes/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-themes___nl",
    path: "/nl/gidsen",
    component: () => import("/vercel/path0/pages/guides-themes/index.vue").then(m => m.default || m)
  },
  {
    name: "guides-themes___en",
    path: "/en/guides",
    component: () => import("/vercel/path0/pages/guides-themes/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "landing-uid___fr",
    path: "/fr/landing/:uid()",
    component: () => import("/vercel/path0/pages/landing/[uid].vue").then(m => m.default || m)
  },
  {
    name: "landing-uid___es",
    path: "/es/landing/:uid()",
    component: () => import("/vercel/path0/pages/landing/[uid].vue").then(m => m.default || m)
  },
  {
    name: "landing-uid___de",
    path: "/de/landing/:uid()",
    component: () => import("/vercel/path0/pages/landing/[uid].vue").then(m => m.default || m)
  },
  {
    name: "landing-uid___nl",
    path: "/nl/landing/:uid()",
    component: () => import("/vercel/path0/pages/landing/[uid].vue").then(m => m.default || m)
  },
  {
    name: "landing-uid___en",
    path: "/en/landing/:uid()",
    component: () => import("/vercel/path0/pages/landing/[uid].vue").then(m => m.default || m)
  },
  {
    name: "legal-uid-child___fr",
    path: "/fr/legal/:uid()/:child()",
    component: () => import("/vercel/path0/pages/legal/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-uid-child___es",
    path: "/es/legal/:uid()/:child()",
    component: () => import("/vercel/path0/pages/legal/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-uid-child___de",
    path: "/de/legal/:uid()/:child()",
    component: () => import("/vercel/path0/pages/legal/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-uid-child___nl",
    path: "/nl/legal/:uid()/:child()",
    component: () => import("/vercel/path0/pages/legal/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-uid-child___en",
    path: "/en/legal/:uid()/:child()",
    component: () => import("/vercel/path0/pages/legal/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-uid___fr",
    path: "/fr/legal/:uid()",
    component: () => import("/vercel/path0/pages/legal/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-uid___es",
    path: "/es/legal/:uid()",
    component: () => import("/vercel/path0/pages/legal/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-uid___de",
    path: "/de/legal/:uid()",
    component: () => import("/vercel/path0/pages/legal/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-uid___nl",
    path: "/nl/legal/:uid()",
    component: () => import("/vercel/path0/pages/legal/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-uid___en",
    path: "/en/legal/:uid()",
    component: () => import("/vercel/path0/pages/legal/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "login___fr",
    path: "/fr/connexion",
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___es",
    path: "/es/iniciar-sesion",
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___de",
    path: "/de/anmelden",
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___nl",
    path: "/nl/inloggen",
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/vercel/path0/pages/login/index.vue").then(m => m.default || m)
  },
  {
    name: "offers___fr",
    path: "/fr/assurances",
    component: () => import("/vercel/path0/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "offers___es",
    path: "/es/seguros",
    component: () => import("/vercel/path0/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "offers___de",
    path: "/de/versicherungen",
    component: () => import("/vercel/path0/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "offers___nl",
    path: "/nl/verzekeringen",
    component: () => import("/vercel/path0/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "offers___en",
    path: "/en/plans",
    component: () => import("/vercel/path0/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-region-country___fr",
    path: "/fr/destinations/:region()/:country()",
    component: () => import("/vercel/path0/pages/regions/[region]/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-region-country___es",
    path: "/es/destinos/:region()/:country()",
    component: () => import("/vercel/path0/pages/regions/[region]/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-region-country___de",
    path: "/de/ziellaender/:region()/:country()",
    component: () => import("/vercel/path0/pages/regions/[region]/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-region-country___nl",
    path: "/nl/destinations/:region()/:country()",
    component: () => import("/vercel/path0/pages/regions/[region]/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-region-country___en",
    path: "/en/destinations/:region()/:country()",
    component: () => import("/vercel/path0/pages/regions/[region]/[country]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-region___fr",
    path: "/fr/destinations/:region()",
    component: () => import("/vercel/path0/pages/regions/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-region___es",
    path: "/es/destinos/:region()",
    component: () => import("/vercel/path0/pages/regions/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-region___de",
    path: "/de/ziellaender/:region()",
    component: () => import("/vercel/path0/pages/regions/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-region___nl",
    path: "/nl/destinations/:region()",
    component: () => import("/vercel/path0/pages/regions/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-region___en",
    path: "/en/destinations/:region()",
    component: () => import("/vercel/path0/pages/regions/[region]/index.vue").then(m => m.default || m)
  },
  {
    name: "regions-destinations___fr",
    path: "/fr/destinations",
    component: () => import("/vercel/path0/pages/regions/destinations.vue").then(m => m.default || m)
  },
  {
    name: "regions-destinations___es",
    path: "/es/destinos",
    component: () => import("/vercel/path0/pages/regions/destinations.vue").then(m => m.default || m)
  },
  {
    name: "regions-destinations___de",
    path: "/de/ziellaender",
    component: () => import("/vercel/path0/pages/regions/destinations.vue").then(m => m.default || m)
  },
  {
    name: "regions-destinations___nl",
    path: "/nl/destinations",
    component: () => import("/vercel/path0/pages/regions/destinations.vue").then(m => m.default || m)
  },
  {
    name: "regions-destinations___en",
    path: "/en/destinations",
    component: () => import("/vercel/path0/pages/regions/destinations.vue").then(m => m.default || m)
  },
  {
    name: "services-uid-child___fr",
    path: "/fr/services/:uid()/:child()",
    component: () => import("/vercel/path0/pages/services/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "services-uid-child___es",
    path: "/es/servicios/:uid()/:child()",
    component: () => import("/vercel/path0/pages/services/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "services-uid-child___de",
    path: "/de/serviceleistungen/:uid()/:child()",
    component: () => import("/vercel/path0/pages/services/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "services-uid-child___nl",
    path: "/nl/diensten/:uid()/:child()",
    component: () => import("/vercel/path0/pages/services/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "services-uid-child___en",
    path: "/en/services/:uid()/:child()",
    component: () => import("/vercel/path0/pages/services/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "services-uid___fr",
    path: "/fr/services/:uid()",
    component: () => import("/vercel/path0/pages/services/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "services-uid___es",
    path: "/es/servicios/:uid()",
    component: () => import("/vercel/path0/pages/services/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "services-uid___de",
    path: "/de/serviceleistungen/:uid()",
    component: () => import("/vercel/path0/pages/services/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "services-uid___nl",
    path: "/nl/diensten/:uid()",
    component: () => import("/vercel/path0/pages/services/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "services-uid___en",
    path: "/en/services/:uid()",
    component: () => import("/vercel/path0/pages/services/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "slice-simulator___fr",
    path: "/fr/slice-simulator",
    component: () => import("/vercel/path0/pages/slice-simulator.vue").then(m => m.default || m)
  },
  {
    name: "slice-simulator___es",
    path: "/es/slice-simulator",
    component: () => import("/vercel/path0/pages/slice-simulator.vue").then(m => m.default || m)
  },
  {
    name: "slice-simulator___de",
    path: "/de/slice-simulator",
    component: () => import("/vercel/path0/pages/slice-simulator.vue").then(m => m.default || m)
  },
  {
    name: "slice-simulator___nl",
    path: "/nl/slice-simulator",
    component: () => import("/vercel/path0/pages/slice-simulator.vue").then(m => m.default || m)
  },
  {
    name: "slice-simulator___en",
    path: "/en/slice-simulator",
    component: () => import("/vercel/path0/pages/slice-simulator.vue").then(m => m.default || m)
  },
  {
    name: "support-uid-child___fr",
    path: "/fr/aide/:uid()/:child()",
    component: () => import("/vercel/path0/pages/support/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-uid-child___es",
    path: "/es/soporte/:uid()/:child()",
    component: () => import("/vercel/path0/pages/support/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-uid-child___de",
    path: "/de/hilfe/:uid()/:child()",
    component: () => import("/vercel/path0/pages/support/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-uid-child___nl",
    path: "/nl/ondersteuning/:uid()/:child()",
    component: () => import("/vercel/path0/pages/support/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-uid-child___en",
    path: "/en/support/:uid()/:child()",
    component: () => import("/vercel/path0/pages/support/[uid]/[child]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-uid___fr",
    path: "/fr/aide/:uid()",
    component: () => import("/vercel/path0/pages/support/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-uid___es",
    path: "/es/soporte/:uid()",
    component: () => import("/vercel/path0/pages/support/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-uid___de",
    path: "/de/hilfe/:uid()",
    component: () => import("/vercel/path0/pages/support/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-uid___nl",
    path: "/nl/ondersteuning/:uid()",
    component: () => import("/vercel/path0/pages/support/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-uid___en",
    path: "/en/support/:uid()",
    component: () => import("/vercel/path0/pages/support/[uid]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-faq-faq___fr",
    path: "/fr/aide/faq/:faq()",
    component: () => import("/vercel/path0/pages/support/faq/[faq]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-faq-faq___es",
    path: "/es/soporte/preguntas-frecuentes/:faq()",
    component: () => import("/vercel/path0/pages/support/faq/[faq]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-faq-faq___de",
    path: "/de/hilfe/haeufig-gestellte-fragen/:faq()",
    component: () => import("/vercel/path0/pages/support/faq/[faq]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-faq-faq___nl",
    path: "/nl/ondersteuning/veel-gestelde-vragen/:faq()",
    component: () => import("/vercel/path0/pages/support/faq/[faq]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-faq-faq___en",
    path: "/en/support/faq/:faq()",
    component: () => import("/vercel/path0/pages/support/faq/[faq]/index.vue").then(m => m.default || m)
  },
  {
    name: "support-faq-faqs___fr",
    path: "/fr/aide/faq",
    component: () => import("/vercel/path0/pages/support/faq/faqs.vue").then(m => m.default || m)
  },
  {
    name: "support-faq-faqs___es",
    path: "/es/soporte/preguntas-frecuentes",
    component: () => import("/vercel/path0/pages/support/faq/faqs.vue").then(m => m.default || m)
  },
  {
    name: "support-faq-faqs___de",
    path: "/de/hilfe/haeufig-gestellte-fragen",
    component: () => import("/vercel/path0/pages/support/faq/faqs.vue").then(m => m.default || m)
  },
  {
    name: "support-faq-faqs___nl",
    path: "/nl/ondersteuning/veel-gestelde-vragen",
    component: () => import("/vercel/path0/pages/support/faq/faqs.vue").then(m => m.default || m)
  },
  {
    name: "support-faq-faqs___en",
    path: "/en/support/faq",
    component: () => import("/vercel/path0/pages/support/faq/faqs.vue").then(m => m.default || m)
  },
  {
    name: "taxonomy-uid___fr",
    path: "/fr/guides/:uid()",
    component: () => import("/vercel/path0/pages/taxonomy/[uid].vue").then(m => m.default || m)
  },
  {
    name: "taxonomy-uid___es",
    path: "/es/guias/:uid()",
    component: () => import("/vercel/path0/pages/taxonomy/[uid].vue").then(m => m.default || m)
  },
  {
    name: "taxonomy-uid___de",
    path: "/de/le%C3%AFtfaden/:uid()",
    component: () => import("/vercel/path0/pages/taxonomy/[uid].vue").then(m => m.default || m)
  },
  {
    name: "taxonomy-uid___nl",
    path: "/nl/gidsen/:uid()",
    component: () => import("/vercel/path0/pages/taxonomy/[uid].vue").then(m => m.default || m)
  },
  {
    name: "taxonomy-uid___en",
    path: "/en/guides/:uid()",
    component: () => import("/vercel/path0/pages/taxonomy/[uid].vue").then(m => m.default || m)
  },
  {
    name: "taxonomy___fr",
    path: "/fr/guides-thematiques",
    component: () => import("/vercel/path0/pages/taxonomy/index.vue").then(m => m.default || m)
  },
  {
    name: "taxonomy___es",
    path: "/es/guides-thematiques",
    component: () => import("/vercel/path0/pages/taxonomy/index.vue").then(m => m.default || m)
  },
  {
    name: "taxonomy___de",
    path: "/de/guides-thematiques",
    component: () => import("/vercel/path0/pages/taxonomy/index.vue").then(m => m.default || m)
  },
  {
    name: "taxonomy___nl",
    path: "/nl/guides-thematiques",
    component: () => import("/vercel/path0/pages/taxonomy/index.vue").then(m => m.default || m)
  },
  {
    name: "taxonomy___en",
    path: "/en/guides-thematiques",
    component: () => import("/vercel/path0/pages/taxonomy/index.vue").then(m => m.default || m)
  }
]